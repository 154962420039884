
.inputSelect:hover,
.inputSelect:focus {
    background-color: #273340 !important;
}

.inputSelect.Mui-selected {
    background-color: rgba(28, 202, 255, 0.2) !important;
    color: rgba(28, 202, 255, 0.95) !important;
}


.MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 9px) scale(1) !important;
    -webkit-transform: translate(14px, 9px) scale(1) !important;
    -moz-transform: translate(14px, 9px) scale(1) !important;
    -ms-transform: translate(14px, 9px) scale(1) !important;
    -o-transform: translate(14px, 9px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    -webkit-transform: translate(14px, -6px) scale(0.75) !important;
    -moz-transform: translate(14px, -6px) scale(0.75) !important;
    -ms-transform: translate(14px, -6px) scale(0.75) !important;
    -o-transform: translate(14px, -6px) scale(0.75) !important;
}

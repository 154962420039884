
.dropDownVisible {
    overflow-x: unset !important;
}

@media (max-width: 615px) {
    .maxWidthDisplayNone {
        display: none;
    }
}
  
.slide-down {
    animation: fade-in 0.2s ease;
    -webkit-animation: fade-in 0.2s ease;
}
  
.slide-up {
    animation: fade-out 0.2s ease;
    -webkit-animation: fade-out 0.2s ease;
}

@keyframes fade-in {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
}
  
@keyframes fade-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
    }
}

  
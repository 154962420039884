
.nav-link-SideNav {
    transition: all 0.1s ease !important;
    -webkit-transition: all 0.1s ease !important;
    -moz-transition: all 0.1s ease !important;
    -ms-transition: all 0.1s ease !important;
    -o-transition: all 0.1s ease !important;
}
  
  .nav-link-SideNav:hover,
  .nav-link-SideNav:focus {
    /* color: rgba(28, 202, 255, 0.8) !important;*/
    background-color: rgba(28, 202, 255, 0.1) !important; /* Jemné pozadie pri hover */
  }


.containerLicenseDetailPageHeader {
    max-width: 102rem;
    min-width: 19rem;
}

.containerLicenseDetailPage {
    display: flex;
    flex-direction: row;
}

.containerLicensePayment {
    display: flex;
    flex-direction: column;
    margin-left: 30px !important;
}

/* .licenseFees {
    margin: 0 !important;
} */

/* .paymentCardContainer {
    margin: 30px 0 0 0 !important;
} */

@media (max-width: 1599px) {
    .containerLicenseDetailPage {
        flex-direction: column;
    }

    .containerLicensePayment {
        flex-direction: row;
        margin-left: 0 !important;
    }

    .licenseFees {
        flex: 1;
        margin: -13px 16px 0 0 !important;
    }

    .paymentCardContainer {
        flex: 1;
    }
}

@media (min-width: 1600px) {
    .licenseFees {
        /* flex: 1; */
        margin: -30px 0px 0 0 !important;
    }

    .containerLicensePayment {
        margin-left: 16px !important;
    }

    .licensePaymentCard {
        margin-top: 0px !important;
    }
  }

@media (max-width: 800px) {
    .containerLicensePayment {
        flex-direction: column;
    }

    .licenseFees {
        margin-right: 0 !important;
    }

    .licensePaymentCard {
        margin-top: 0px !important;
    }
}

.memberIdCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    /* width: max-content; */
    gap: 0.6rem;
}

.identifyMemberContainer {
    flex: 2;
}

.identifyMemberContainerHosting {
    flex: 1;
}

.dataCardContainerHosting {
    flex: 1;
}

.dataCardContainer {
    flex: 1;
}


@media (max-width: 1279px) {
    .transferPaymentCard {
      margin-top: -30px !important;
      /* padding-left: 22px !important; */
    }
  }


@media (max-width: 1170px) {
    .memberIdCardContainer {
        flex-direction: column;
    }

    .identifyMemberContainerHosting,
    .identifyMemberContainer {
        width: 27rem;
    }
    
    .dataCardContainerHosting {
        width: 100%;
    }
}

@media (max-width: 675px) {
    .memberIdCardContainer,
    .identifyMemberContainerHosting,
    .identifyMemberContainer {
        width: unset;
    }
}
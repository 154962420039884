
label.Mui-focused {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.readOnlyInput fieldset {
  /* border-color: #fff !important; */
  border-color: rgba(60, 72, 88, 0.4) !important;
}

@media (max-width: 615px) {
  .checkBoxBtnContainer {
    left: 1.9rem;
    right: 1.9rem;
    overflow-x: scroll;
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    white-space: nowrap;
    scrollbar-width: none;
  }
}

@media (min-width: 959px) {
  .personalDataCard {
    margin-top: 0px !important;
  }

  .memberFunctionsTable {
    margin-top: 30px !important;
  }
}


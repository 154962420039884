
.priceListContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.OldPriceContainer {
    width: 60%;
}

.EditPriceListContainer {
    width:40%
}

.priceToggleBtn {
    width: 25%;
    border-radius: 10px;
    color: #F5F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer;
    height: 40px;
    border: 2px solid #364759;
}

.priceToggleBtn:hover {
    background-color: #364759;
}

.toggle-icon {
    transition: transform 0.2s ease;
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -ms-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
}

.expanded {
    transform: translateY(0px) rotate(180deg);
    -webkit-transform: translateY(0px) rotate(180deg);
    -moz-transform: translateY(0px) rotate(180deg);
    -ms-transform: translateY(0px) rotate(180deg);
    -o-transform: translateY(0px) rotate(180deg);
}

@media (max-width: 1420px) {
    .priceListContainer {
      flex-direction: column;
    } 

    .OldPriceContainer,
    .EditPriceListContainer {
        width: 100%;
    }
   
}


.slide-down {
    opacity: 1;
    transform: translateY(0);
    animation: fade-in 0.2s ease-in;
    -webkit-animation: fade-in 0.2s ease-in;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }

.slide-up {
    opacity: 0;
    transform: translateY(0);
    animation: fade-out 0.2s ease-in;
    -webkit-animation: fade-out 0.2s ease-in;
}

@keyframes fade-out {
    0% {
      opacity: 1;
      transform: translateY(50px);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}




.dateRangeButton {
    color: white !important;
}

.dateRangeButton:hover,
.dateRangeButton:focus {
  /* color: rgba(28, 202, 255, 0.8) !important;*/
  background-color: rgba(28, 202, 255, 0.1) !important; /* Jemné pozadie pri hover */
}
